import React from 'react'
import './portfolio.css'
import me from '../../assets/project1.png'
import me1 from '../../assets/project2.png'
import me2 from '../../assets/project3.png'
import me3 from '../../assets/project4.png'
import me4 from '../../assets/project5.png'
import me5 from '../../assets/project6.png'

const portfolio = () => {
  return (
    <section id='projects'>
      <h5>My Recent work</h5>
      <h2>Project Portfolio</h2>
      <div className="container portfolio_container">
        <article className='portfolio_item'>
          <div className="portfolio_item-img">
            <img src={me} alt="" />
          </div>
          
          <div className="portfolio_container-cta">
          <h3>Team Trees Website</h3>
          <a href="https://github.com/" className='btn btn-primary1' target='__blank'>Code</a>
          <a href="https://github.com/" className='btn btn-primary1' target='__blank'>View Demo</a>
          </div>
        </article>
        <article className='portfolio_item'>
          <div className="portfolio_item-img">
            <img src={me1} alt="" />
          </div>
          
          <div className="portfolio_container-cta">
          <h3>Simple Ecommerce website</h3>
          <a href="https://github.com/" className='btn btn-primary1' target='__blank'>Code</a>
          <a href="https://github.com/" className='btn btn-primary1' target='__blank'>View Demo</a>
          </div>
        </article>
        <article className='portfolio_item'>
          <div className="portfolio_item-img">
            <img src={me2} alt="" />
          </div>
          <div className="portfolio_container-cta">
          <h3>Product Landing Page</h3>
          <a href="https://github.com/" className='btn btn-primary1' target='__blank'>Code</a>
          <a href="https://github.com/" className='btn btn-primary1' target='__blank'>View Demo</a>
          </div>
        </article>
        <article className='portfolio_item'>
          <div className="portfolio_item-img">
            <img src={me3} alt="" />
          </div>
          <div className="portfolio_container-cta">
          <h3>ToDo App</h3>
          <a href="https://github.com/" className='btn btn-primary1' target='__blank'>Code</a>
          <a href="https://github.com/" className='btn btn-primary1' target='__blank'>View Demo</a>
          </div>
        </article>
        <article className='portfolio_item'>
          <div className="portfolio_item-img">
            <img src={me4} alt="" />
          </div>
          <div className="portfolio_container-cta">
          <h3>Signup/Login Page</h3>
          <a href="https://github.com/" className='btn btn-primary1' target='__blank'>Code</a>
          <a href="https://github.com/" className='btn btn-primary1' target='__blank'>View Demo</a>
          </div>
        </article>
        <article className='portfolio_item'>
          <div className="portfolio_item-img">
            <img src={me5} alt="" />
          </div>
          <div className="portfolio_container-cta">
          <h3>Personal Portfolio Website</h3>
          <a href="https://github.com/" className='btn btn-primary1' target='__blank'>Code</a>
          <a href="https://github.com/" className='btn btn-primary1' target='__blank'>View Demo</a>
          </div>
        </article>
      </div>

    </section>
  )
}

export default portfolio
import React from 'react'
import './header.css'
import {FaFacebook} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'
import {FaLinkedin} from 'react-icons/fa'
import {FaGithub} from 'react-icons/fa'

const header = () => {
  return (
    <header>
      <div className="container header_container">

        <div className="header_content">
          <h2>Hi,</h2>
          <p>I am      <span className='name'>Ujjwal Kuikel</span></p>
          {/* <h2>Fullstack Developer</h2> */}
          <h4>I build great brands and website.</h4>
          <h5>Just ask <a href="#testimonials">my mom</a>!</h5>
          <div className="hire">
            <a href="#contact">Hire me!</a>
          </div>
        </div>
        <div className="headericon">
          <a href="https://facebook.com/ujjwal.kuikel.3" target='__blank'><FaFacebook /></a>
          <a href="https://www.instagram.com/ujjwal_kuikel/" target='__blank'><FaInstagram /></a>
          <a href="https://www.linkedin.com/in/ujjwal-kuikel-872786182/" target='__blank'><FaLinkedin /></a>
          <a href="https://github.com/ujjwalkuikel" target='__blank'><FaGithub /></a>          
        </div>
        <div className="headerswipe">
          <a href='#contact'>Swipe down</a>
        </div>



      </div>


    </header>
  )
}

export default header
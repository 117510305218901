import React from 'react'
import './about.css'
import me from '../../assets/ujjwal2.png'
import {BsBriefcaseFill} from 'react-icons/bs'
import {FaLaptopCode} from 'react-icons/fa'
import {BsFillPeopleFill} from 'react-icons/bs'

const about = () => {
  return (
    <section id='about'>
      <h5>Get to know</h5>
      <h2>About me</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-img">
            <img src={me} alt="" />
          </div>
        </div>
      
      <div className="about_content">
        <div className="about_cards">
          <article className="about_card">
            <BsBriefcaseFill/>
            <h5>Experience</h5>
            <small>2+years</small>
          </article>
          <article className="about_card">
            <FaLaptopCode/>
            <h5>Projects</h5>
            <small>20+</small>
          </article>
          <article className="about_card">
            <BsFillPeopleFill/>
            <h5>Clients</h5>
            <small>15+</small>
          </article>
        </div>
        <p>I am Ujjwal Kuikel working as a freelance software engineer since past 2 years. I have good knowledge of graphics designing, web development and digital marketing. You can contact me for any kind of problems, I will deliver you a tailored solution satisfying all your business requirements.</p>
      <a href="#contact" className='btn btn-primary'>Lets Talk!</a>
      </div>
    </div>
  </section>
  )
}

export default about
import React from 'react'
import './contact.css'
import { HiMail } from 'react-icons/hi'
import { FaFacebookMessenger } from 'react-icons/fa'
import { IoIosCall } from 'react-icons/io'

const contact = () => {
  return (
    <section id='contact'>
      <h5>Get in touch</h5>
      <h2>Contact</h2>
      <div className="container contact_container">
        <div className="contact_options">
          <article className="contact_option">
            <div className="iconset"><HiMail className='contact_option-icon' />
              <h4>Email</h4></div>
            <h5>ujjwalkuikel3@gmail.com</h5>
            <a href="mailto:ujjwalkuikel3@gmail.com" target='__blank'>Send mail</a>
          </article>
          <article className="contact_option">
            <div className="iconset">
              <FaFacebookMessenger className='contact_option-icon' />
              <h4>Messenger</h4></div>
            <h5>Ujjwal Kuikel</h5>
            <a href="https://m.me/ujjwal.kuikel.3" target='__blank'>Send Message</a>
          </article>
          <article className="contact_option">
            <div className="iconset">
              <IoIosCall className='contact_option-icon' />
              <h4>Call</h4></div>
            <h5>+977-9816070012</h5>
            <a href="tel:+9779816070012">Call me</a>
          </article>
        </div>

        <form action="">
          <input type="text" name="name" id="" placeholder='Enter your name' required />
          <input type="email" name="name" id="" placeholder='Enter your email' required />
          <textarea name="message" rows="7" placeholder='Enter your message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default contact
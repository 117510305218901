import React from 'react'
import './company.css'
import com1 from '../../assets/disney.png'
import com2 from '../../assets/spring.png'
import com3 from '../../assets/lg.png'
import com4 from '../../assets/spark.png'
import com5 from '../../assets/geb.png'

const company = () => {
    return (
        <section id='company'>
            <h5>Whom I have worked for</h5>
            <h2>Companies</h2>
            <div className="container company">
                <div className="imggroup">
                    <div className="img">
                        <img src={com1} alt="" />
                    </div>
                    <div className="img">
                        <img src={com2} alt="" />
                    </div>
                    <div className="img">
                        <img src={com3} alt="" />
                    </div>
                    <div className="img">
                        <img src={com4} alt="" />
                    </div>
                    <div className="img">
                        <img src={com5} alt="" />
                    </div>
                </div>

            </div>

        </section>
    )
}

export default company
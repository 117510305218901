import React from 'react'
import './exp.css'
import me from '../../assets/sk1.png'
import me1 from '../../assets/sk2.png'
import me2 from '../../assets/sk3.png'
import me3 from '../../assets/sk4.png'
import me4 from '../../assets/sk5.png'
import me5 from '../../assets/sk6.png'
import me6 from '../../assets/sk7.png'
import me7 from '../../assets/sk8.png'
import me8 from '../../assets/sk9.png'
import me9 from '../../assets/sk10.png'

const exp = () => {
  return (
    <section id='experience'>
      <h5>Skills I possess</h5>
      <h2 className='exptitle'>My experiences</h2>
      <div className="container expdiv">
        <div className="expset">
          <h2>Website Development</h2>
          <div className="skillset">
            <article className='article'>
              <div className="skillimg"><img src={me} alt="" /></div>
              <h4>Wordpress</h4>
              <small>Experienced</small>
            </article>
            <article className='article'>
            <div className="skillimg"><img src={me1} alt="" /></div>
              <h4>HTML5</h4>
              <small>Experienced</small>
            </article>
            <article className='article'>
            <div className="skillimg"><img src={me2} alt="" /></div>
              <h4>CSS(Bootstrap)</h4>
              <small>Experienced</small>
            </article>
            <article className='article'>
            <div className="skillimg"><img src={me3} alt="" /></div>
              <h4>JS (React JS)</h4>
              <small>Intermediate</small>
            </article>
            <article className='article'>
            <div className="skillimg"><img src={me4} alt="" /></div>
              <h4>Java Springboot</h4>
              <small>Intermediate</small>
            </article>
          </div>
        </div>


        <div className="expset">
          <h2>Graphic Designing and Digital Marketing</h2>
          <div className="skillset">
            <article className='article'>
            <div className="skillimg"><img src={me5} alt="" /></div>
              <h4>Photoshop</h4>
              <small>Experienced</small>
            </article>
            <article className='article'>
            <div className="skillimg"><img src={me6} alt="" /></div>
              <h4>Illustrator</h4>
              <small>Beginner</small>
            </article>
            <article className='article'>
            <div className="skillimg"><img src={me7} alt="" /></div>
              <h4>Canva</h4>
              <small>Experienced</small>
            </article>
            <article className='article'>
            <div className="skillimg"><img src={me8} alt="" /></div>
              <h4>SEO(On-page)</h4>
              <small>Intermediate</small>
            </article >
            <article className='article'>
            <div className="skillimg"><img src={me9} alt="" /></div>
              <h4>Ads Manager</h4>
              <small>Intermediate</small>
            </article>
          </div>
        </div>
      </div>


    </section>
  )
}

export default exp
import React from 'react'
import './testi.css'
import me from '../../assets/ujjwal4.jpeg'

const testi = () => {

  return (
    <section id='testimonials'>
      <h5>Review from my mom</h5>
      <h2>Testimonials</h2>

      <div className="container testi_container">
        
              <article className='testimonial'>
              <div className="client_avatar">
                <img src={me} alt="" />
              </div>
              <h5 className='client_name'>Ujjwal's mom</h5>
              <small className='client_review'>Ujjwal was born energetic, inquisitive and curious. Always, THINKING OUTSIDE OF THE BOX, he is natural explorer with limitless curiosity. He has determination and grit but he is also very kind person. He can be lazy sometime but he loves coding and designing. He always enjoyed writing, designing and sharing his work with us.<br/> As his mom, perhaps I'm biased. But he does everything with absoulute passion. He takes clients problem as his own and works to create absolute perfect solution meeting all the clients requirements. You should work with him once and you will realise how wonderful that decision was. </small>
            </article>
      </div>
      
    </section>
  
  )
}
export default testi
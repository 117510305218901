import React from 'react'
import Header from './components/header/header'
import Nav from './components/nav/nav'
import About from './components/About/about'
import Experience from './components/experience/exp'
import Service from './components/services/services'
import Portfolio from './components/portfolio/portfolio'
import Testimonial from './components/testimonials/testi'
import Contact from './components/contact/contact'
import Footer from './components/footer/footer'
import Company from './components/companies/company'


const app = () => {
  return (
    <>
    <Header />
    <Nav/>
    <About/>
    <Experience/>
    <Company/>
    <Portfolio/>
    <Service />
    <Testimonial/>
    <Contact/>
    <Footer/>

    </>
  )
}

export default app
import React from 'react'
import './nav.css'
import {AiFillHome} from 'react-icons/ai'
import {BsPersonFill} from 'react-icons/bs'
import {BsBriefcaseFill} from 'react-icons/bs'
import {MdMiscellaneousServices} from 'react-icons/md'
import {BsChatRightTextFill} from 'react-icons/bs'
import {FaLaptopCode} from 'react-icons/fa'
import {IoMdMail} from 'react-icons/io'
import { useState } from 'react'
import {BsBuilding} from 'react-icons/bs'

const Nav = () => {
  const[active,setActive]=useState('#')
  return (
    <nav>
      <a href="#" onClick={()=>setActive('#')} className={active==='#' ? 'active':''}><AiFillHome/></a>
      <a href="#about" onClick={()=>setActive('#about')} className={active==='#about' ? 'active':''}><BsPersonFill/></a>
      <a href="#experience" onClick={()=>setActive('#experience')} className={active==='#experience' ? 'active':''}><BsBriefcaseFill/></a>
      <a href="#company" onClick={()=>setActive('#company')} className={active==='#company' ? 'active':''}><BsBuilding/></a>
      <a href="#projects" onClick={()=>setActive('#projects')} className={active==='#projects' ? 'active':''}><FaLaptopCode/></a>
      <a href="#services" onClick={()=>setActive('#services')} className={active==='#services' ? 'active':''}><MdMiscellaneousServices/></a>
      <a href="#testimonials" onClick={()=>setActive('#testimonials')} className={active==='#testimonials' ? 'active':''}><BsChatRightTextFill/></a>
      <a href="#contact" onClick={()=>setActive('#contact')} className={active==='#contact' ? 'active':''}><IoMdMail/></a>



    </nav>
  )
}

export default Nav
import React from 'react'
import './footer.css'
import {FaFacebook} from 'react-icons/fa'
import {FaInstagram} from 'react-icons/fa'
import {FaLinkedin} from 'react-icons/fa'

const footer = () => {
  return (
    <footer>
      <a href="#" className='footer_logo'>Ujjwal</a>
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#projects">Projects</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
      <div className="footer_socials">
        <a href="https://facebook.com/ujjwal.kuikel.3" target='_blank'><FaFacebook/></a>
        <a href="https://www.instagram.com/ujjwal_kuikel/" target='_blank'><FaInstagram/></a>
        <a href="hhttps://www.linkedin.com/in/ujjwal-kuikel-872786182/" target='_blank'><FaLinkedin/></a>
      </div>
      <div className="footer_cpright">
        <small>Copyright <a href='https://ujjwalkuikel.com.np'>&copy;Ujjwal Kuikel.</a> All rights reserved.</small>
      </div>
    </footer>
  )
}

export default footer
import React from 'react'
import './services.css'
import { BiCheck } from 'react-icons/bi'
const services = () => {
  return (
    <section id='services'>
      <h5>What I offer</h5>
      <h2>Services</h2>
      <div className="container service_container">
        <article className='service'>
          <div className="service_head">
            <h3>Graphic Designing</h3>
          </div>
          <ul className="service_list">
            <li><BiCheck className='servicelist_icon' /><p>Branding : Logo Design and Brand Identity</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Promotional Marketing : Brochures, Posters, Greeting Cards, Invitations</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Business Stationery : Business Cards, Folders</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Promotional Branded Products : Mugs, Notepads, T-shirts, Clothing, Bags, Caps</p></li>
           
          </ul>
        </article>
        <article className='service'>
          <div className="service_head">
            <h3>Website Designing</h3>
          </div>
          <ul className="service_list">
            <li><BiCheck className='servicelist_icon' /><p>Wordpress website development</p></li>
            <li><BiCheck className='servicelist_icon' /><p>HTML5/CSS/Vanilla JS websites.</p></li>
            <li><BiCheck className='servicelist_icon' /><p>React JS websites.</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Ecommerce websites</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Blogging websites.</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Newsportal websites.</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Redesign and customize existing websites</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Migrating websites from one server to another.</p></li>
          </ul>
        </article>
        <article className='service'>
          <div className="service_head">
            <h3>Digital marketing</h3>
          </div>
          <ul className="service_list">
            <li><BiCheck className='servicelist_icon' /><p>Search Engine Optimization</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Social Media Marketing.(Facebook Ads Manager)</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Content Writing.(copywriting, blog writing)</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Pay-per-click marketing</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Email marketing.</p></li>
            <li><BiCheck className='servicelist_icon' /><p>Search Engine Marketing(Google Ads)</p></li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default services